
import React from "react";
import Clip from '../../assets/img/videos/Karaoke.mp4';

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function SectionDownload() {
  return (
    <>
      <div className="section" style={{ backgroundColor: '#000000' }}>
        <Container className="text-center">

          <h3 style={{ alignContent: 'center', color: 'white', textAlign: 'center' }}>¡Nosotros nos encargamos de hacer las noches de Karaoke más divertidas!</h3>
          <hr style={{ borderTop: '3px solid white' }} />
          <br />
          <div style={{ borderRadius: '15px', position: 'relative', height: 'auto', width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <video style={{ width: '100vw', height: 'auto', minHeight: '100%' }} className="video-player" preload="auto" autoPlay="autoplay" loop muted playsInline>
              <source src={Clip} type="video/mp4" />
            </video>
          </div>
          <br />
          <hr style={{ borderTop: '3px solid orange' }} />
          <br />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{ color: 'white', fontWeight: 'bolder' }}>¿Te gusto nuestro servicio?</h2>
              <p className="description" style={{ color: 'white' }}>
                Nos gustaría saber tu opinión sobre nuestros servicios, tus comentarios son muy valiosos para nosotros, nos ayudara mucho para darte una mejor estancia.
              </p>
            </Col>
            <Col className="ml-auto mr-auto download-area" md="5">
              <Button
                className="btn-round"
                color="danger"
                href="#"
                target="_blank"
              >
                ¿NOS AYUDAS CON UNA ENCUESTA?
              </Button>
            </Col>
            <Col className="ml-auto mr-auto download-area" md="5" lg="8" >
              <h2 style={{ color: 'white', fontWeight: 'bolder' }}>¿Ya nos sigues?</h2>
            </Col>
            <Col className="ml-auto mr-auto download-area" md="5" lg="8" >
              <Button
                className="twitter-sharrre btn-round"
                color="twitter-bg"
                href="#pablo"
                id="tooltip3373767"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-twitter" /> Twitter
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip3373767">
                Tweet!
              </UncontrolledTooltip>
              <Button
                className="linkedin-sharrre btn-round  ml-2"
                color="google-bg"
                href="#pablo"
                id="tooltip840791273"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-instagram" /> Instagram
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip840791273">
                Share!
              </UncontrolledTooltip>
              <Button
                className="facebook-sharrre btn-round ml-2"
                color="facebook-bg"
                href="#pablo"
                id="tooltip68961360"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-facebook-square" /> Facebook
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip68961360">
                Share!
              </UncontrolledTooltip>
              {/* <Button
                className="sharrre btn-round ml-2"
                color="github-bg"
                href="https://github.com/creativetimofficial/paper-kit-react?ref=creativetim"
                target="_blank"
                id="tooltip864353654"
              >
                <i className="fa fa-github" /> Star
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip864353654">
                Star on Github
              </UncontrolledTooltip> */}
              <h3 style={{ color: 'white', fontWeight: 'bolder' }}>!Muchas gracias por tu elección!</h3>
            </Col>
          </Row>
          {/* <Row className="text-center upgrade-pro">
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title" style={{color: 'white'}}>Want more?</h2>
              <p className="description"style={{color: 'white'}}>
                We're going to launch{" "}
                <a className="text-danger" href="#pablo" disabled>
                  Paper Kit PRO React in a few weeks
                </a>
                . It will have a huge number of components, sections and example
                pages.
              </p>
            </Col> 
            <Col className="ml-auto mr-auto" sm="5">
              <Button className="btn-round" color="info" href="#pablo" disabled>
                <i aria-hidden={true} className="nc-icon nc-spaceship" />{" "}
                Upgrade to PRO
              </Button>
            </Col> 
          </Row>*/}

        </Container>
      </div>
    </>
  );
}

export default SectionDownload;
