import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionDark() {
  return (
    <>
      <div className="section section-orange">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" >
              <h3 className="title" style={{color: 'white', fontWeight: 'bolder'}}>CONTACTANOS</h3>
              <h3 className="description" style={{color: 'white'}}>
              <i className="fa fa-phone" /><span style={{fontWeight: 'bolder'}}>(55)-50-88-72-31</span> 
              </h3>
              <h3 className="description" style={{color: 'white'}}>
              <i className="fa fa-whatsapp" /><span style={{fontWeight: 'bolder'}}>(56)-18-36-10-65</span> 
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionDark;
