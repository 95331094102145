const nachos = "Nachos";
const papas = "Papas";
const menu = [
    {
        id: 1,
        title: "Papas Gajo",
        category: 'Entradas',
        desc: "300gr de Deliciosas papas gajo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 49.00
    },
    {
        id: 2,
        title: "Papas Curly",
        category: 'Entradas',
        desc: "250gr de Deliciosas papas curly",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 58.00
    },
    {
        id: 3,
        title: "Papas a la Francesa",
        category: 'Entradas',
        desc: "300gr de Deliciosas papas a la francesa",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 48.00
    },
    {
        id: 4,
        title: "Elote Dulce",
        category: 'Entradas',
        desc: "250gr de exquisito elote dulce",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 43.00
    },
    /* {
        id: 5,
        title: "Jalapeños Rellenos",
        category: 'Entradas',
        desc: "6 pzs de crujientes jalapeños rellenos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    }, */
    {
        id: 6,
        title: "Vegetales con Aderezo",
        category: 'Entradas',
        desc: "Vegetales con Aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 49.00
    },
    {
        id: 7,
        title: "Dedos de Queso",
        category: 'Entradas',
        desc: "6 pzs de deliciosos dedos de queso acompañados con salsa de tomate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 129.00
    },
    {
        id: 8,
        title: "Aros de Cebolla",
        category: 'Entradas',
        desc: "6pzs de crujientes aros de cebolla",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 49.00
    },
    {
        id: 9,
        title: "Nuggets",
        category: 'Entradas',
        desc: "6 pzs de deliciosos Nuggets de pollo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 69.00
    },
    /*Alitas*/
    {
        id: 10,
        title: "Paq. Alitas de 5 Alitas",
        category: 'Alitas',
        desc: "Alitas acompañadas de su salsa preferida, acompañadas con vegetales y aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 93.00
    },
    {
        id: 11,
        title: "Paq. Alitas de 10 Alitas",
        category: 'Alitas',
        desc: "Alitas acompañadas de su salsa preferida, acompañadas con vegetales y aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 159.00
    },
    {
        id: 12,
        title: "Paq. Alitas de 25 Alitas",
        category: 'Alitas',
        desc: "Alitas acompañadas de su salsa preferida, acompañadas con vegetales y aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 369.00
    },
    {
        id: 13,
        title: "Paq. Alitas de 50 Alitas",
        category: 'Alitas',
        desc: "Alitas acompañadas de su salsa preferida, acompañadas con vegetales y aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 709.00
    },
    /* Costillas */
    {
        id: 14,
        title: "Costilla Individual",
        category: 'Costillas',
        desc: "300gr de costilla acompañada con salsa de tu elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 106.00
    },
    {
        id: 15,
        title: "Costilla Duo",
        category: 'Costillas',
        desc: "600gr de costilla con dos salsas a elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 177.00
    },
    {
        id: 16,
        title: "Costilla para compartir",
        category: 'Costillas',
        desc: "900gr de costilla con tres salsas a elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 247.00
    },
    /* Paquetes */
    {
        id: 17,
        title: "Pa' que Revientes",
        category: 'Paquetes',
        desc: "Buffet de alitas, costillas y papa al horno sencilla. Reglas: Duración de consumo 2 horas, paquete individual, no compartir, consumir bebidas, consumir todo lo que se ordena, no hay para llevar y no aplica otras promociones",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 319.00
    },
    {
        id: 18,
        title: "Combo #1 Alitas",
        category: 'Paquetes',
        desc: "25pz de exquisitas alitas, 2 ordenes de papas a la francesa y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 459.00
    },
    {
        id: 19,
        title: "Combo #2 Alitas",
        category: 'Paquetes',
        desc: "50pz de exquisitas alitas, papas a la francesa, papas gajo y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 789.00
    },
    {
        id: 20,
        title: "Combo #1 Costillas",
        category: 'Paquetes',
        desc: "Costilla duo (600gr), papas gajo y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 249.00
    },
    {
        id: 21,
        title: "Combo #2 Costillas",
        category: 'Paquetes',
        desc: "Costilla para compartir (900gr) papas gajo, papas francesas y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 379.00
    },
    /* Tenders */
    {
        id: 22,
        title: "Tenders Individual",
        category: 'Tenders',
        desc: "300g. de crujientes tenders acompañados con su salsa favorita",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 23,
        title: "Tenders Duo",
        category: 'Tenders',
        desc: "600g. de crujientes tenders de pollo acompañada con 2 salsas a elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 229.00
    },
    {
        id: 24,
        title: "Tenders para Compartir",
        category: 'Tenders',
        desc: "900g. de crujientes tenders acompañados con 3 salsas a elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 329.00
    },
    {
        id: 25,
        title: "Tenders Familiar",
        category: 'Tenders',
        desc: "1.200kg.. de crujiente tender, con 4 posibles salsas a elección",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 419.00
    },
    /* Boneless */
    {
        id: 26,
        title: "Boneless 150G",
        category: 'Boneless',
        desc: "Deliciosos boneless con salsa de su elección y vegetales y aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 27,
        title: "Boneless 300G",
        category: 'Boneless',
        desc: "Deliciosos boneless acompañados con dos salsas a elección y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 179.00
    },
    {
        id: 28,
        title: "Boneless 600G",
        category: 'Boneless',
        desc: "Deliciosos boneless acompañados con 4 salsas a elección y vegetales con aderezo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 359.00
    },
    /* Hamburguesas */
    {
        id: 29,
        title: "Hamburguesa Sencilla",
        category: 'Hamburguesas',
        desc: "150gr de exquisita carne de res, mix de quesos, chile, acompañada de lechuga, jitomate y cebolla.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 104.00
    },
    {
        id: 30,
        title: "Hamburguesa Original",
        category: 'Hamburguesas',
        desc: "150gr de exquisita carne de res, bañada en la salsa de tu elección, chile, aros de cebolla, mix de quesos, acompañada de lechuga, jitomate y cebolla.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 134.00
    },
    {
        id: 31,
        title: "Hamburguesa Tropical",
        category: 'Hamburguesas',
        desc: "150gr de exquisita carne de res, piña, chile, tiras de tocino, mix de quesos, acompañada de lechuga, jitomate y cebolla",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 125.00
    },
    {
        id: 32,
        title: "Hamburguesa Champiqueso",
        category: 'Hamburguesas',
        desc: "150gr de exquisita carne de res, chile, champiñones salteados con mantequilla, cebolla caramelizada, mix de quesos, acompañada de lechuga y jitomate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 33,
        title: "Hamburguesa Marga pollo",
        category: 'Hamburguesas',
        desc: "100gr de exquisita pechuga de pollo empanizada, chile, bañada en la salsa de tu elección, mix de quesos, acompañada de lechuga, jitomate y cebolla",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 88.00
    },
    /* {
        id: 34,
        title: "Hamburguesa Marinera",
        category: 'Hamburguesas',
        desc: "100gr de exquisito atún a la plancha, cebolla caramelizada, mix de quesos, acompañada de lechuga, jitomate y cebolla",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    }, */
    {
        id: 35,
        title: "Hamburguesa Arrachera",
        category: 'Hamburguesas',
        desc: "100gr de exquisita arrachera, cebolla caramelizada, chile, mix de quesos, acompañada de lechuga y jitomate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 36,
        title: "Hamburguesa Enchilada",
        category: 'Hamburguesas',
        desc: "100gr de exquisita carne enchilada, guacamole, mix de quesos, acompañada de lechuga y jitomate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 37,
        title: "Hamburguesa Mar y Tierra",
        category: 'Hamburguesas',
        desc: "150g de exquisita carne de res, deliciosos camarones, cebolla caramelizada, guacamole, mix de quesos, acompañada de lechuga y jitomate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 189.00
    },

    /* Papas */
    {
        id: 38,
        title: "Papa al Horno Sencilla",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, gratinada con mix de quesos y cebollín",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 69.00
    },
    {
        id: 39,
        title: "Papa al Horno Tocino Seco",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada de tocino seco y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 82.00
    },
    {
        id: 40,
        title: "Papa al Horno Chistorra",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada de 100gr chistorra y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 129.00
    },
    {
        id: 41,
        title: "Papa al Horno Arrachera",
        category: 'PapasAlHorno',
        desc: "150gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr de fajitas de arrachera y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },
    {
        id: 42,
        title: "Papa al Horno Hawaiana",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr de cubos de jamón y piña y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 43,
        title: "Papa al Horno Bolognesa",
        category: 'PapasAlHorno',
        desc: "120gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr carne molida en salsa bolognesa y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 44,
        title: "Papa al Horno Veggie",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr de champiñones, granos de elote, zanahoria y gratinada con mix de queso",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 45,
        title: "Papa al Horno Tiras de Pollo",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr de tiras de pollo y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 112.00
    },
    {
        id: 46,
        title: "Papa al Horno Tender",
        category: 'PapasAlHorno',
        desc: "200gr de Deliciosa papa horneada con mantequilla, acompañada con 100gr de tender de pollo y gratinada con mix de quesos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },

    /* EspecialesMargalitas */
    {
        id: 47,
        title: "Marga Mix",
        category: 'EspecialesMargalitas',
        desc: "Marga Mix.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 269.00
    },
    {
        id: 48,
        title: "Costilla Baby",
        category: 'EspecialesMargalitas',
        desc: "Deliciosas costillas baby back, bañada en la salsa de tu eleccion, acompañadas de papa gajo y elote dulce.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 299.00
    },
    {
        id: 49,
        title: "Pizza Individual",
        category: 'EspecialesMargalitas',
        desc: "Deliciosa pizza individual, acompañada de papas a la francesa.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 50,
        title: "Fajitas de Pollo",
        category: 'EspecialesMargalitas',
        desc: "Deliciosas fajitas de pollo sazonadas, guacamole, acompañadas de cebolla, pimiento rojo, verde y torillas.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 129.00
    },
    {
        id: 51,
        title: "Fajitas de Arrachera",
        category: 'EspecialesMargalitas',
        desc: "Deliciosas fajitas de arrachera, guacamole, sazonadas acompañadas de cebolla, pimiento rojo, verde y tortillas.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 149.00
    },
    {
        id: 52,
        title: "Fajitas de Camaron",
        category: 'EspecialesMargalitas',
        desc: "Deliciosas fajitas de Camaron, guacamole",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 229.00
    },

    /* Papas y Nachos Especiales */
    {
        id: 53,
        title: "Nachos Sencillos",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Sencillos",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 54,
        title: "Nachos Arrachera",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Arrachera",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 134.00
    },
    {
        id: 55,
        title: "Nachos Bolognesa",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Bolognesa",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 134.00
    },
    {
        id: 56,
        title: "Nachos Chistorra",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Chistorra",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },
    {
        id: 57,
        title: "Nachos Tocino Seco",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Tocino Seco",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 94.00
    },
    {
        id: 58,
        title: "Nachos Tender",
        category: 'PapasNachosEspeciales',
        desc: "Nachos Tender",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },

    /* Papas Especiales */
    {
        id: 59,
        title: "Papas Sencillas Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Sencillas Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 60,
        title: "Papas Arrachera Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Arrachera Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 61,
        title: "Papas Bolognesa Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Bolognesa Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 62,
        title: "Papas Chistorra Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Chistorra Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 122.00
    },
    {
        id: 63,
        title: "Papas Tocino Seco Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Tocino Seco Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 64,
        title: "Papas Tender Especiales",
        category: 'PapasNachosEspeciales',
        desc: "Papas Tender Especiales",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 122.00
    },

    /* HotDogs */
    {
        id: 65,
        title: "PUG",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, tocino, mix de quesos, aderezos, cebolla jitomate y chile.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 66,
        title: "El Pipeco",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, mix de quesos, aderezos, cebolla, jitomate y chile.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 74.00
    },
    {
        id: 67,
        title: "BullDog",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, tocino, mix de quesos, aderezos, chile serrano y cebollas salteadas.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 68,
        title: "Aleman",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, tocino, mix de quesos, aderezos, chipotle, piña y cebollas salteadas.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 69,
        title: "Doberman",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, tocino, mix de quesos y guacamole.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 84.00
    },
    {
        id: 70,
        title: "Danes",
        category: 'Hot-Dogs',
        desc: "Deliciosa salchicha de pavo, tocino, mix de quesos, arrachera, salsa de frijol, salsa de queso, aderezos, jitomate, cebolla y chile.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 94.00
    },
    {
        id: 71,
        title: "Gran Pipeco",
        category: 'Hot-Dogs',
        desc: "Gran Pipeco",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 149.00
    },
    {
        id: 72,
        title: "Gran PUG",
        category: 'Hot-Dogs',
        desc: "Gran PUG",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 159.00
    },
    {
        id: 73,
        title: "Gran BullDog",
        category: 'Hot-Dogs',
        desc: "Gran BullDog",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 159.00
    },
    {
        id: 74,
        title: "Gran Aleman",
        category: 'Hot-Dogs',
        desc: "Gran Aleman",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 159.00
    },
    {
        id: 75,
        title: "Gran Doberman",
        category: 'Hot-Dogs',
        desc: "Gran Doberman",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 169.00
    },
    {
        id: 76,
        title: "Gran Danes",
        category: 'Hot-Dogs',
        desc: "Gran Danes",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 179.00
    },

    /* Ensaladas */
    {
        id: 77,
        title: "Ensalada Caesar",
        category: 'Ensaladas',
        desc: "Lechuga orejona, pechuga de pollo a la plancha, queso parmesano, crocantes croutones, jitomate cherry y aderezo caesar.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 78,
        title: "Ensalada Atun",
        category: 'Ensaladas',
        desc: "Mix de lechugas frescas, atún, pepino, cebolla morada, crocantes croutones, jitomate cherry y aderezo blue cheese.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 79,
        title: "Ensalada Frutal",
        category: 'Ensaladas',
        desc: "Mix de lechugas frescas, 3 frutas de temporada, queso de cabra, nuez y aderezo honey mustard.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 95.00
    },
    {
        id: 80,
        title: "Ensalada Camaron",
        category: 'Ensaladas',
        desc: "Ensalada Camaron",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 179.00
    },

]

export default menu;
