import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div className="section section-dark">

        <Container>
        <hr style={{ borderTop: '2px solid white' }} />
          <h2 style={{ alignContent: 'center', fontWeight: 'bolder', color: 'white', textAlign: 'center' }}>¡Conoce nuestro menú de bebidas que tenemos para ti!</h2>
          <hr style={{ borderTop: '2px solid white' }} />
          <Row className="example-page">
            <Col className="text-center" md="6">
              <a href="examples/landing.html" target="_blank">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/first.png").default}
                  style={{ width: "100%" }}
                />
              </a>
              {/* <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href="/landing-page"
                target="_blank"
              >
                Landing Page
              </Button> */}
            </Col>
            <Col className="text-center" md="6">
              <a href="examples/profile.html" target="_blank">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/second.png").default}
                  style={{ width: "100%" }}
                />
              </a>
              {/* <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href="/profile-page"
                target="_blank"
              >
                Profile Page
              </Button> */}
            </Col>
          </Row>
          
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionExamples;
