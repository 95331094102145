import React from "react";
import { Dropdown } from "react-bootstrap";

const Categories = ({ categories, filterItems, activeCategory }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" style={{ width: '100%' }} id="dropdown-basic">
        Selecciona Categoria
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ backgroundColor: '#000000', width: '100%' }}>
        <div style={{ height: '400px', width: '100%', overflowX: 'hidden' }}>
          {categories.map((category, index) => {
            return (
              <Dropdown.Item style={{ backgroundColor: '#000000', color: '#FFFFFF', fontWeight: 'bolder', fontSize: '16px', textAlign: 'center', width: '100%' }}
                type="button" className={`${activeCategory === category ? "filter-btn active" : "filter-btn"}`} key={index}
                onClick={() => filterItems(category)}>
                {category}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
      <hr style={{marginBottom: '-5px'}}/>
    </Dropdown>
  );
};

export default Categories;
