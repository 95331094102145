const menu = [
    {
        id: 1,
        title: "Hamburguesa",
        category: 'Hamburguesa',
        desc: "80gr de carne de res gratinada con mix de quesos, lechuga, jitomate, acompañada con 150gr de papas a la francesa",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },
    {
        id: 2,
        title: "Nuggets",
        category: 'Snacks',
        desc: "Infantil",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 3,
        title: "Dedos de queso",
        category: 'Snacks',
        desc: "Infantil",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 139.00
    },
    {
        id: 4,
        title: "Mini pizza",
        category: 'Pizza',
        desc: "Infantil",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 5,
        title: "Hot-Dog",
        category: 'HotDog',
        desc: "Infantil",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 6,
        title: "5 Alitas",
        category: 'Alitas',
        desc: "Infantil",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    
];

export default menu;
