import { React, useState } from 'react';
import "../../assets/css/styles.css";
import "../../assets/css/box.css";
import Header from "./Header";

import { ModalGenerico, ModalGenericoBebidas, ModalGenericoCocteles, ModalGenericoInfantil } from "../GenericModal/ModalGenerico";
import { Button } from 'react-bootstrap';
import { Container } from "reactstrap";
//Importaciones de menus
import AlimentosMenu from 'components/AlimentosMenu/AlimentosMenu';
import InfantilMenu from 'components/InfantilMenu/InfantilMenu';
import BebidasMenu from 'components/BebidasMenu/BebidasMenu';
import PostresMenu from 'components/PostresMenu/PostresMenu';

export default function ViewMenu(props) {

    const [modalShow, setModalShow] = useState(false);
    const [modalBebidaShow, setModalBebidaShow] = useState(false);
    const [modalCoctelShow, setModalCoctelShow] = useState(false);
    const [modalInfantilShow, setModalInfantilShow] = useState(false);

    const Alimentos = <AlimentosMenu />;
    const Infantil = <InfantilMenu />;
    const Bebidas = <BebidasMenu />;
    const Postres = <PostresMenu />;

    return (
        <>
            <div style={{ paddingBottom: '50px', backgroundColor: '#000000' }} className="content-center">
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <Header name="Menu" />
                        {/* Alimentos Menu */}
                        <Button style={{ border: '2px solid white', borderRadius: '35px', backgroundColor: 'transparent' }} onClick={() => setModalShow(true)}>
                            Menu Alimentos
                        </Button>
                        <ModalGenerico Titulo='Menu Alimentos' Menu='Alimentos' Body={Alimentos} show={modalShow} onHide={() => setModalShow(false)} />

                        {/* Bebidas Menu */}
                        <Button style={{ border: '2px solid white', borderRadius: '35px', backgroundColor: 'transparent' }} onClick={() => setModalBebidaShow(true)}>
                            Menu Bebidas
                        </Button>
                        <ModalGenericoBebidas Titulo='Menu Bebidas' Menu='Bebidas' Body={Bebidas} show={modalBebidaShow} onHide={() => setModalBebidaShow(false)} />

                        {/* Postres y Cocteles Menu */}
                        <Button style={{ border: '2px solid white', borderRadius: '35px', backgroundColor: 'transparent' }} onClick={() => setModalCoctelShow(true)}>
                            Menu Postres, Sodas & Malteadas
                        </Button>
                        <ModalGenericoCocteles Titulo='Menu Postres, Refresco & Malteadas' Menu='Postres' Body={Postres} show={modalCoctelShow} onHide={() => setModalCoctelShow(false)} />

                        {/* Infantil Menu */}
                        <Button style={{ border: '2px solid white', borderRadius: '35px', backgroundColor: 'transparent' }} onClick={() => setModalInfantilShow(true)}>
                            Menu Infantil
                        </Button>
                        <ModalGenericoInfantil Titulo='Menu Infantil' Menu='Infantil' Body={Infantil} show={modalInfantilShow} onHide={() => setModalInfantilShow(false)} />
                    </div>
                </Container>
            </div>
        </>
    )
}