import "../../assets/css/nav-bar.css";
import Categoria from "components/Categorias/Categoria";
const Header = ({ name, basket }) => {
    const openNav = () => {
        document.getElementById("mySidenav").style.height = "300px";
    };

    return (
        <nav className="navbar navbar-expand-md navigation-clean sticky-top">
            <div style={{ width: '100%' }} >
                <a style={{ color: 'white' }} className="navbar-brand">
                    {name}
                </a>
            </div>
        </nav>
    );
};

export default Header;
