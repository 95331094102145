import React from "react";
import logo from '../../assets/img/margalitas-logo.png';
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import { Button, Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar style={{backgroundColor: '#000000'}} className={classnames("fixed-top", navbarColor)} expand="sm">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_blank"
            title="Coded by Creative Tim"
          >
            <img style={{ width: 'auto', height: '200px', marginTop: '-90px', marginBottom: '-90px' }} src={logo} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >

          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
              style={{color:'#FFFFFF'}}
                href="https://www.facebook.com/Margalitas" target="_blank"
              >
                <i
                  style={{color:'#FFFFFF'}}
                  aria-hidden={true}
                  className="fa fa-facebook-official"
                />
                Facebook
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              style={{color:'#FFFFFF'}}
                href="#" target="_blank"
              >
                <i style={{color:'#FFFFFF'}} aria-hidden={true} className="fa fa-twitter" />
                Twitter
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              style={{color:'#FFFFFF'}}
                href="https://instagram.com/margalitasmx?igshid=1n3ww3muo7u3d" target="_blank">
                <i style={{color:'#FFFFFF'}} aria-hidden={true} className="fa fa-instagram" />
                Instagram
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              style={{color:'#FFFFFF'}}
                href="https://delivery.softrestaurant.com.mx/menu/Margalitas#/menu" target="_blank"
              >
                <i style={{color:'#FFFFFF'}} className="nc-icon nc-book-bookmark" /> Menu
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
