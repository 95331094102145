import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import Logo from '../../assets/img/prueba.png';

// core components

function SectionNucleoIcons() {

  return (
    <>
      <div className="section section-orange section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title" style={{ color: 'white', textAlign: 'center', fontWeight: 'bolder' }}>#SOMOSMARGALITAS</h3>
              
              <p className="description" style={{ color: 'white', textAlign: 'justify' }}>
              ¡Un lugar donde obtendrás una gran experiencia acompañado de tu familia y amigos! Somos la catedral del karaoke, en donde encontraran la mayor diversión bailando al ritmo de la música que nos comparten nuestros cantantes profesionales. ¡Es una experiencia que no podrás olvidar!
              </p>
            </Col>
            <Col lg="6" md="12">
              <div style={{ textAlign: 'center', alignItems: 'center' }}>
                <img style={{ width: '100%', maxWidth: '400px', marginBottom: '-90px', marginTop: '-70px' }} src={Logo} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionNucleoIcons;
