
const menu = [
    {
        id: 1,
        title: "Malteada de Chocolate",
        category: 'Malteadas',
        desc: "Malteada de Chocolate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 2,
        title: "Malteada de Vainilla",
        category: 'Malteadas',
        desc: "Malteada de Chocolate",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 3,
        title: "Malteada de Oreo",
        category: 'Malteadas',
        desc: "Malteada de Oreo",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 4,
        title: "Malteada de Fresa",
        category: 'Malteadas',
        desc: "Malteada de Fresa",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    /* Refrescos */
    {
        id: 5,
        title: "Coca Cola",
        category: 'Refrescos',
        desc: "Coca Cola",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 6,
        title: "Coca Cola Sin Azucar",
        category: 'Refrescos',
        desc: "Coca Cola Sin Azucar",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 7,
        title: "Coca Cola Light",
        category: 'Refrescos',
        desc: "Coca Cola Light",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 8,
        title: "Sprite",
        category: 'Refrescos',
        desc: "Sprite",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 9,
        title: "Fresca",
        category: 'Refrescos',
        desc: "Fresca",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 10,
        title: "Ciel Mineral",
        category: 'Refrescos',
        desc: "Ciel Mineral",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 11,
        title: "Fanta",
        category: 'Refrescos',
        desc: "Fanta",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 12,
        title: "Sidral Mundet",
        category: 'Refrescos',
        desc: "Sidral Mundet",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 13,
        title: "Delaware Punch",
        category: 'Refrescos',
        desc: "Delaware Punch",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 14,
        title: "Boing",
        category: 'Refrescos',
        desc: "Fresa, Manzana, Mango, Guayaba",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 15,
        title: "Ginger Ale",
        category: 'Refrescos',
        desc: "Ginger Ale",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 16,
        title: "Fuzetea Negro",
        category: 'Refrescos',
        desc: "Limon & Durazno",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 17,
        title: "Fuzetea Verde",
        category: 'Refrescos',
        desc: "Limon",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 18,
        title: "Sangria",
        category: 'Refrescos',
        desc: "Sangria",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 19,
        title: "Botella de Agua",
        category: 'Refrescos',
        desc: "Botella de Agua",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    /* BebidasSinAlcohol */
    {
        id: 20,
        title: "Limonada Electrica",
        category: 'BebidasSinAlcohol',
        desc: "Limonada Electrica",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 59.00
    },
    {
        id: 21,
        title: "Canica",
        category: 'BebidasSinAlcohol',
        desc: "Canica",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 59.00
    },
    {
        id: 22,
        title: "Piñada",
        category: 'BebidasSinAlcohol',
        desc: "Piñada",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 69.00
    },
    {
        id: 23,
        title: "Conga",
        category: 'BebidasSinAlcohol',
        desc: "Conga",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 59.00
    },
    {
        id: 24,
        title: "Margarita Frozz Sin Alcohol",
        category: 'BebidasSinAlcohol',
        desc: "Margarita Frozz",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 25,
        title: "Clamato Preparado",
        category: 'BebidasSinAlcohol',
        desc: "Clamato Preparado",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 59.00
    },
    /* Limonada y Naranjada */
    {
        id: 26,
        title: "Limonada Mineral",
        category: 'Limonada-Naranjada',
        desc: "Limonada Mineral",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 27,
        title: "Limonada Natural",
        category: 'Limonada-Naranjada',
        desc: "Limonada Natural",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 28,
        title: "Naranjada Mineral",
        category: 'Limonada-Naranjada',
        desc: "Naranjada Mineral",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 29,
        title: "Naranjada Natural",
        category: 'Limonada-Naranjada',
        desc: "Naranjada Natural",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 30,
        title: "Naranjada Mineral 1LT",
        category: 'Limonada-Naranjada',
        desc: "Naranjada Mineral 1LT",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },
    {
        id: 31,
        title: "Limonada Mineral 1LT",
        category: 'Limonada-Naranjada',
        desc: "Limonada Mineral 1LT",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 89.00
    },

    /* Jarras Limonada Naranjada */
    {
        id: 32,
        title: "Jarra Limonada Mineral",
        category: 'Jarra-Limonada-Naranjada',
        desc: "Jarra Limonada Mineral",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 33,
        title: "Jarra Naranjada Mineral",
        category: 'Jarra-Limonada-Naranjada',
        desc: "Jarra Naranjada Mineral",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 34,
        title: "Jarra Limonada Natural",
        category: 'Jarra-Limonada-Naranjada',
        desc: "Jarra Limonada Natural",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 35,
        title: "Jarra Naranjada Natural",
        category: 'Jarra-Limonada-Naranjada',
        desc: 'Jarra-Limonada-Naranjada',
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },

    /* Jugos */
    {
        id: 36,
        title: "Jugo de Arandano",
        category: 'Jugos',
        desc: "Arandano",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 34.00
    },
    {
        id: 37,
        title: "Jugo de Uva",
        category: 'Jugos',
        desc: "Uva",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 34.00
    },
    {
        id: 38,
        title: "Jugo de Naranja",
        category: 'Jugos',
        desc: "Naranja",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 34.00
    },
    {
        id: 39,
        title: "Jugo de Piña",
        category: 'Jugos',
        desc: "Piña",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 34.00
    },

    /* Cafes */
    {
        id: 40,
        title: "Americano",
        category: 'Cafes',
        desc: "Americano",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },
    {
        id: 41,
        title: "Capuccino",
        category: 'Cafes',
        desc: "Capuccino",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 49.00
    },
    {
        id: 42,
        title: "Espresso",
        category: 'Cafes',
        desc: "Espresso",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 29.00
    },
    {
        id: 43,
        title: "Té",
        category: 'Cafes',
        desc: "Té",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 39.00
    },

    /* Postres */
    {
        id: 44,
        title: "Cheesecake para compartir Frutos Rojos",
        category: 'Postres',
        desc: "Base de galleta graham con delicioso pay de queso y salsa de frutos rojos.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 45,
        title: "Tarta de Manzana",
        category: 'Postres',
        desc: "Deliciosa y crujiente, tarta de manzana caliente, acompañada de una bola de helado de vainilla.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 69.00
    },
    {
        id: 46,
        title: "Brownie con Helado",
        category: 'Postres',
        desc: "El clasico brownie de chocolate oscuro, acompañado de una bola de helado de vainilla.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 109.00
    },
    {
        id: 47,
        title: "Smores",
        category: 'Postres',
        desc: "Disfruta de preparas deliciosos smores, la estación de servicio incluye galleta graham, chocolate y bombones.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 48,
        title: "Helado Irlandes",
        category: 'Postres',
        desc: "Tres bolas de helado de vainilla, bañadas en crema irlandesa, con trozos de nuez.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 99.00
    },
    {
        id: 49,
        title: "Cheesecake para compartir Oreo",
        category: 'Postres',
        desc: "Base de galleta oreo, con delicioso pay de queso.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 119.00
    },
    {
        id: 50,
        title: "Tarta de Chocolate Blanco y Zarzamoras",
        category: 'Postres',
        desc: "Deliciosa y crujiente tarta de chocolate blanco y zarzamoras.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 51,
        title: "Cheesecake individual Frutos Rojos",
        category: 'Postres',
        desc: "Cheesecake individual frutos rojos.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },
    {
        id: 52,
        title: "Cheesecake individual Oreo",
        category: 'Postres',
        desc: "Cheesecake individual Oreo.",
        img: "https://i.ibb.co/jMR3sFp/alitas.png",
        price: 79.00
    },

]

export default menu;
