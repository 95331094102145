import React from "react";

const Menu = ({ items }) => {
  return (
    <div style={{ width: '100%'}}>
      {items.map((item) => {
        const { id, title, img, desc, price } = item;
        return (
          <article style={{width: '100%'}} key={id} className="menu-item">
            {/* <img src={img} alt={title} className="photo" /> */}
            <div className="item-info" style={{ width: '100%' }}>
              <header style={{width: '100%', height: 'auto'}}>
                <h3 style={{ color: 'white', fontWeight: 'bolder' }}>{title}</h3>
                <h3 style={{ fontWeight: 'bolder', color: '#ffc600' }} >${price}</h3>
              </header>
            </div>
            <div className="item-info" style={{ width: '100%' }}>
              <p className="item-text" style={{ color: 'orange' }}>{desc}</p>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Menu;
